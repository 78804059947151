@import "./assets/fontawesome.css";

$body-color: #222;
$theme-colors: (
  "primary": #0f55eb,
  "danger": #ff4136
);

@import "node_modules/bootstrap/scss/bootstrap";

.form-control {
  height: 32px;
  font-size: .8rem;
}
.btn {
  font-size: .8rem;
}
@media (min-width: 768px) {
  .form-control {
    height: 36px;
    font-size: .9rem;
  }
  .btn {
    font-size: 1rem;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footnote {
  font-size: 80%;
  color: #777777;
  font-weight: 300;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}

svg [stroke="dc173e"] {
  stroke: var(--primary);
}

svg [fill="#dc173e"] {
  fill: var(--primary);
}
